import { useCallback, useMemo } from 'react';

import { useI18n } from '@change/core/react/i18n';
import { useTracking } from '@change/core/react/tracking';

import { usePetitionDetailsPageData } from 'src/app/pages/petition/details/pageContext';

type Result = ModelHookResult<
	{
		buttonAmounts: ReadonlyArray<{ amount: number; amountLocalized: string; link: string }>;
		selectedAmount: { amount: number; amountLocalized: string };
		buttonText: string;
		otherLink: string;
		ctaButtonLink: string;
	},
	{
		onClick: () => void;
	}
>;

type Props = {
	onClick: () => void;
};

export function useButtonContainer(props: Props): Result {
	const track = useTracking();
	const { translate, amountToBaseUnits } = useI18n();
	const {
		data: {
			petition: { slug },
			currency,
			subscriptionConfig,
		},
	} = usePetitionDetailsPageData();

	const encodedURI = useMemo(() => encodeURIComponent(slug), [slug]);

	const buttonAmounts = useMemo(() => {
		if (!subscriptionConfig?.amounts) return [];
		return subscriptionConfig.amounts.map(({ amount, amountLocalized }) => {
			return {
				amount,
				amountLocalized,
				link: `/p/${encodedURI}/psf/membership?source_location=petition_details&amount=${amountToBaseUnits(amount, currency)}`,
			};
		});
	}, [amountToBaseUnits, currency, encodedURI, subscriptionConfig]);

	const onClick = useCallback(() => {
		void track('petition_membership_popup_click');
		props.onClick();
	}, [track, props]);

	const selectedAmount = subscriptionConfig?.defaultAmount ?? { amount: 0, amountLocalized: '0' };
	return {
		data: {
			selectedAmount,
			buttonAmounts,
			buttonText: translate('fe.pages.sponsors.continue'),
			otherLink: `/p/${encodedURI}/psf/membership?source_location=petition_details&amount=0`,
			ctaButtonLink: `/p/${encodedURI}/psf/membership?source_location=petition_details&amount=${amountToBaseUnits(selectedAmount.amount, currency)}`,
		},
		actions: {
			onClick,
		},
	};
}
