import { useSessionStorageValueAsync } from 'src/app/shared/hooks/storage';

export function useBannerClosedStateStorage() {
	const [bannerClosed, setBannerClosed] = useSessionStorageValueAsync('membershipBottomBannerClosed');

	return {
		bannerClosed: bannerClosed === undefined || bannerClosed === 'true',
		setBannerClosed,
	};
}
