import { Button } from '@change/design-system/components/actions';
import { iconClose } from '@change/design-system/icons';
import { Box, Container, Flex } from '@change/design-system/layout';
import { derivedStyle } from '@change/design-system/theme';

import { ButtonContainer } from './components/ButtonContainer';
import { TextContainer } from './components/TextContainer';
import { useLayout } from './hooks/useLayout';

export function Layout(): React.JSX.Element | null {
	const {
		data: { visible, ref },
		actions: { onClickClose },
	} = useLayout();

	if (!visible) return null;

	return (
		<Box
			ref={ref}
			sx={{
				display: ['none', 'block'],
				position: 'fixed',
				backgroundColor: '#FAF7ED',
				boxShadow: '0 0 1px 1px rgb(0 0 0 / 20%)',
				padding: 24,
				width: '100%',
				bottom: 0,
				left: 0,
				zIndex: derivedStyle(({ zIndices }) => zIndices.cookieWall - 1),
			}}
			data-testid="membership-bottom-banner"
			role="alert"
		>
			<Box sx={{ position: 'fixed', right: 20 }}>
				<Button
					data-testid="close-icon"
					data-qa="membership-popup-close-button"
					sx={{ cursor: 'pointer', backgroundColor: 'transparent', borderColor: 'transparent' }}
					onClick={onClickClose}
					aria-labelledby="close-icon"
					color="black"
					icon={iconClose}
					variant="secondary"
					mode="icon"
					size="small"
				>
					Close
				</Button>
			</Box>
			<Container variant="small">
				<Flex sx={{ gap: 32, flexWrap: 'wrap' }}>
					<Flex sx={{ flex: 5 }}>
						<TextContainer />
					</Flex>
					<Flex sx={{ flex: 3 }}>
						<ButtonContainer onClick={onClickClose} />
					</Flex>
				</Flex>
			</Container>
		</Box>
	);
}
