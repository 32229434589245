import { useEffect, useState } from 'react';

import { useIsEligible } from './hooks/useIsEligible';

export function useMembershipBottomBanner() {
	const [waitTimeCompleted, setWaitTimeCompleted] = useState<boolean>(false);
	const isEligible = useIsEligible();

	useEffect(() => {
		if (!isEligible) return () => {};

		const timer = setTimeout(() => {
			setWaitTimeCompleted(true);
		}, 10000);

		return () => {
			clearTimeout(timer);
		};
	}, [isEligible]);

	return {
		displayed: waitTimeCompleted,
	};
}
