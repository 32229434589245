import { useCallback, useEffect, useMemo, useState } from 'react';

import { useUtilityContext } from '@change/core/react/utilityContext';

import { getIframelyData } from 'src/app/shared/api/medias';
import { useCampaignTracking } from 'src/app/shared/hooks/campaignTracking';

import { useFcm } from '../../../hooks/fcm/syncHooks';
import { usePetitionDetails } from '../../../hooks/petitionDetails';
import { useUserInfo } from '../../../hooks/userInfo';
import { fetchMediaHits } from '../api/getMediaHits';

import type { MediaHitComment, MediaHitComments, MediaHitEmbed } from './types';

export type MediaHitsContextValue = {
	data: {
		mediaHitComments: MediaHitComments | undefined;
		mediaEmbeds: MediaHitEmbed[];
		loadingAllEmbeds: boolean;
		loadingSingleEmbed: boolean;
		isMaxMediaLimitReached: boolean;
		submitStatus: 'success' | 'error' | 'max_limit' | undefined;
		maxMediaLimit: number | undefined;
	};
	actions: {
		addMedia: (id: string, url: string) => void;
		removeMedia: (mediaId: string) => void;
		setSubmitStatus: (status: 'success' | 'error' | 'max_limit' | undefined) => void;
		setNewlyAddedMedia: (media: MediaHitComment) => void;
	};
};

// eslint-disable-next-line max-lines-per-function, max-statements
export function useMediaHitsContextValue() {
	const [mediaHitComments, setMediaHitComments] = useState<MediaHitComments | undefined>();
	const [mediaEmbeds, setMediaEmbeds] = useState<MediaHitEmbed[]>([]);
	const [loadingAllEmbeds, setLoadingAllEmbeds] = useState(false);
	const [isMaxMediaLimitReached, setIsMaxMediaLimitReached] = useState(false);
	const [submitStatus, setSubmitStatus] = useState<'success' | 'error' | 'max_limit' | undefined>();
	const [newlyAddedMedia, setNewlyAddedMedia] = useState<MediaHitComment | undefined>();
	const [loadingSingleEmbed, setLoadingSingleEmbed] = useState(false);
	const [numberOfStarterMedia, setNumberOfStarterMedia] = useState(0);

	const { id: petitionId } = usePetitionDetails();
	const utilityContext = useUtilityContext();
	const { track } = useCampaignTracking();
	const { ownPetition } = useUserInfo();

	const fcm = useFcm();
	const MAX_MEDIA_LIMIT = fcm.petitionsMediaHitsConfig?.maximumPerPetition;

	const loadMedias = useCallback(
		async (mediaHits: MediaHitComments | undefined) => {
			if (mediaHits) {
				setLoadingAllEmbeds(true);
				const mediasPromises = mediaHits.map(async ({ url, id, mediaSource }) => {
					try {
						const response = await getIframelyData({ url, format: 'BIG' }, utilityContext);
						return { valid: true, id, url, mediaSource, html: response.html };
					} catch (err) {
						// do not throw when iframely fails to generate an embed
						// either due to an broken website, article removed, network failure,etc
						// but catch the error and return an object with an valid: false property
						return { valid: false, id, url, mediaSource, html: '' };
					}
				});
				const petitionMedias = await Promise.all(mediasPromises);

				setLoadingAllEmbeds(false);
				setMediaEmbeds(petitionMedias);
			}
		},
		[utilityContext],
	);

	const loadSingleMedia = useCallback(
		async (newMediaHit: MediaHitComment | undefined) => {
			if (newMediaHit) {
				const { id, url, mediaSource } = newMediaHit;
				setLoadingSingleEmbed(true);
				try {
					const response = await getIframelyData({ url, format: 'BIG' }, utilityContext);
					setMediaEmbeds((curState) => [{ valid: true, id, url, mediaSource, html: response.html }, ...curState]);
				} catch {
					track('media_hit_iframely_embed_failure', {
						petition_id: petitionId,
						media_url: url,
					});
					setMediaEmbeds((curState) => [{ valid: false, id, url, mediaSource, html: '' }, ...curState]);
				} finally {
					setLoadingSingleEmbed(false);
				}
			}
		},
		[utilityContext, track, petitionId],
	);

	useEffect(() => {
		async function getMediaHits() {
			const result = await fetchMediaHits(petitionId, utilityContext);
			setMediaHitComments([...result.mediaHits]);
			setNumberOfStarterMedia(result.numberOfStarterMedia);
		}
		void getMediaHits();
	}, [petitionId, utilityContext]);

	useEffect(() => {
		void loadMedias(mediaHitComments);
	}, [mediaHitComments, loadMedias]);

	useEffect(() => {
		void loadSingleMedia(newlyAddedMedia);
	}, [newlyAddedMedia, loadSingleMedia]);

	useEffect(() => {
		if (MAX_MEDIA_LIMIT && numberOfStarterMedia >= MAX_MEDIA_LIMIT) {
			setIsMaxMediaLimitReached(true);
		} else {
			setIsMaxMediaLimitReached(false);
		}
	}, [MAX_MEDIA_LIMIT, numberOfStarterMedia]);

	const removeMedia = useCallback(
		(mediaId: string) => {
			const toDelete = mediaEmbeds.find((media) => media.id === mediaId);
			const remainingMediaEmbeds = mediaEmbeds.filter((media) => media?.id !== mediaId);
			setMediaEmbeds(remainingMediaEmbeds);
			if (toDelete?.mediaSource === 'STARTER_ADDED') {
				setNumberOfStarterMedia(numberOfStarterMedia - 1);
			}
		},
		[mediaEmbeds, numberOfStarterMedia],
	);

	const addMedia = useCallback(
		(id: string, url: string) => {
			if (ownPetition) {
				setNewlyAddedMedia({ id, url, mediaSource: 'STARTER_ADDED' });
				setNumberOfStarterMedia(numberOfStarterMedia + 1);
			} else {
				setNewlyAddedMedia({ id, url, mediaSource: 'STAFF_ADDED' });
			}
		},
		[numberOfStarterMedia, ownPetition],
	);

	const value = useMemo(
		() => ({
			data: {
				mediaEmbeds,
				mediaHitComments,
				loadingAllEmbeds,
				loadingSingleEmbed,
				isMaxMediaLimitReached,
				submitStatus,
				maxMediaLimit: MAX_MEDIA_LIMIT,
			},
			actions: {
				addMedia,
				removeMedia,
				setSubmitStatus,
				setNewlyAddedMedia,
			},
		}),
		[
			mediaEmbeds,
			mediaHitComments,
			loadingAllEmbeds,
			loadingSingleEmbed,
			isMaxMediaLimitReached,
			MAX_MEDIA_LIMIT,
			submitStatus,
			addMedia,
			removeMedia,
			setSubmitStatus,
		],
	);

	return value;
}
