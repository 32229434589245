import { Translate } from '@change/core/react/i18n';
import { Box, Flex } from '@change/design-system/layout';
import { Heading, Text } from '@change/design-system/typography';

export function TextContainer(): React.JSX.Element | null {
	return (
		<Flex sx={{ minWidth: 250, flexDirection: 'column', gap: 16 }}>
			<Box>
				<Heading as="h3" size="h3" sx={{ lineHeight: 'unset' }}>
					<Translate value="corgi.membership.awareness.headline_part1" /> — &nbsp;
					<Box sx={{ display: 'inline-block' }}>
						<span
							sx={{
								position: 'relative',
								zIndex: 1,
								backgroundColor: 'secondary-yellow',
								borderRadius: 4,
								px: 4,
							}}
						>
							<Translate value="corgi.membership.awareness.headline_part2" />
						</span>
					</Box>
				</Heading>
			</Box>
			<Translate value="corgi.membership.awareness.description" />
			<Text fontWeight="bold">
				<Translate value="corgi.membership.awareness.ask" />
			</Text>
		</Flex>
	);
}
