import type { JSX } from 'react';

import { withPrefetchedData } from '@change/core/react/prefetch';
import { useKnownAvailableParams } from '@change/core/react/router';
import { Loader } from '@change/design-system/components/progressiveDisclosure';

import type { PetitionDetailsPagePrefetchedDataProps } from 'src/app/pages/petition/details/pageContext';
import {
	PetitionDetailsPageContextProvider,
	prefetchPageContext,
	prefetchPageUserContext,
} from 'src/app/pages/petition/details/pageContext';
import type { PetitionTabProps } from 'src/app/pages/petition/shared/tabTypes';
import { isLoaded } from 'src/app/shared/utils/async';

import { DetailsWithContext } from './DetailsWithContext';
import { PetitionDetailsErrorHandler } from './ErrorHandler';

type Props = PetitionTabProps &
	Readonly<{
		slug: string;
	}>;

export const CommonPetitionDetails = withPrefetchedData(
	function CommonPetitionDetails({
		prefetchedData,
		prefetchedUserData,
		userInfoState,
	}: Props & PetitionDetailsPagePrefetchedDataProps): JSX.Element {
		const { slug } = useKnownAvailableParams<{ slug: string }>();

		return (
			<PetitionDetailsPageContextProvider
				slug={slug}
				prefetchedData={prefetchedData}
				prefetchedUserData={prefetchedUserData}
				userInfo={userInfoState}
				errorHandler={PetitionDetailsErrorHandler}
				loadingFallback={<Loader size="m" my={256} mx="auto" />}
			>
				<DetailsWithContext slug={slug} />
			</PetitionDetailsPageContextProvider>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchData: async (context) => {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const data = await prefetchPageContext(context.params.slug!, context);
			return {
				...data,
				// FIXME this is a temporary way to provide the petitionId to the PX sensor for apps/front-end/src/app/createRoutes.tsx
				perimeterx:
					data.pageData && isLoaded(data.pageData)
						? {
								petitionId: data.pageData.pageData.petition.id,
							}
						: undefined,
			};
		},
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		prefetchUserData: async (context) => prefetchPageUserContext(context.params.slug!, context),
	},
);
