import React from 'react';

import { useMembershipBottomBanner } from './hooks/useMembershipBottomBanner';
import { Layout } from './layout';

export function MembershipBottomBanner(): React.JSX.Element | null {
	const { displayed } = useMembershipBottomBanner();
	if (!displayed) return null;

	return <Layout />;
}
