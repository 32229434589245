import { useEffect } from 'react';

import { useUtilityContext } from '@change/core/react/utilityContext';

import { usePetitionDetailsPageData, usePetitionDetailsPageFcm } from 'src/app/pages/petition/details/pageContext';
import { useCountryCode } from 'src/app/shared/hooks/l10n';
import { useIsMembershipEligibleUser } from 'src/app/shared/hooks/membership';
import { useIsMobile } from 'src/app/shared/hooks/userAgent';

import { useBannerClosedStateStorage } from '../../../useBannerClosedStateStorage';

export function useIsEligible(): boolean {
	const { membershipBottomBannerEnabled } = usePetitionDetailsPageFcm();
	const {
		data: { subscriptionConfig },
	} = usePetitionDetailsPageData();
	const { bannerClosed } = useBannerClosedStateStorage();
	const isMembershipEligible = useIsMembershipEligibleUser();
	const { errorReporter } = useUtilityContext();
	const countryCode = useCountryCode();
	const isMobile = useIsMobile();

	const baseEligibilityCriteria = !isMobile && isMembershipEligible && membershipBottomBannerEnabled && !bannerClosed;

	useEffect(() => {
		if (baseEligibilityCriteria && !subscriptionConfig) {
			void errorReporter.report(
				{
					error: new Error(`Subscription Configuration FC not setup for ${countryCode}`),
				},
				{
					severity: 'info',
				},
			);
		}
	}, [baseEligibilityCriteria, countryCode, errorReporter, subscriptionConfig]);

	return baseEligibilityCriteria && !!subscriptionConfig;
}
